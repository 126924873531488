import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";

const FaqStructuredData = ({ faqResource = "faq.sections" }) => {
    const { t } = useTranslation();

    /* Pre-define the FAQ structured-data schema, which is used to generate the JSON-LD */
    const faqStructuredDataSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [],
    };

    t(faqResource, { returnObjects: true }).map((section) => {
        section.qa.map((qa) => {
            /* Build up the faq json+ld element */
            faqStructuredDataSchema.mainEntity.push({
                "@type": "Question",
                name: qa[0],
                acceptedAnswer: {
                    "@type": "Answer",
                    text: qa[1],
                },
            });
        });
    });

    return (
        <Helmet>
            {Object.keys(faqStructuredDataSchema).length && (
                <script type="application/ld+json">{JSON.stringify(faqStructuredDataSchema)}</script>
            )}
        </Helmet>
    );
};

export default FaqStructuredData;
