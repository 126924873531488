import React from "react";
import { Helmet } from "react-helmet";

const LocalBusinessStructuredData = () => {
    /* Pre-define the FAQ structured-data schema, which is used to generate the JSON-LD */
    const localBusinessStructuredDataSchema = {
        "@context": "https://schema.org",
        "@type": "FinancialService",
        name: "miracl",
        image: process.env.GATSBY_SITE_URL + "/og-image-miracl.png",
        "@id": process.env.GATSBY_SITE_URL,
        url: process.env.GATSBY_SITE_URL,
        telephone: "+43 670 3085171",
        address: {
            "@type": "PostalAddress",
            streetAddress: "Lindengasse 26/1/5, 1070 Wien",
            addressLocality: "Wien",
            postalCode: "1070",
            addressCountry: "AT",
        },
        geo: {
            "@type": "GeoCoordinates",
            latitude: 48.14393751978414,
            longitude: 16.309347884740394,
        },
        openingHoursSpecification: {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            opens: "08:00",
            closes: "19:00",
        },
        sameAs: [
            "https://www.facebook.com/miracl.at.finanzierung/",
            "https://www.instagram.com/miracl.at/",
            process.env.GATSBY_SITE_URL,
        ],
    };

    return (
        <Helmet>
            {<script type="application/ld+json">{JSON.stringify(localBusinessStructuredDataSchema)}</script>}
        </Helmet>
    );
};

export default LocalBusinessStructuredData;
