import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import FaqStructuredData from "../../components/seo/FaqStructuredData";
import LocalBusinessStructuredData from "../../components/seo/LocalBusinessStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import Cooperation from "../../components/Cooperation";
import HomeInfoBoxes from "../../components/HomeInfoBoxes";
import MediaLogos from "../../components/MediaLogos";

const Kreditrechner = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents
                title={t("page.meta.title")}
                description={t("page.meta.description")}
                canonical={{
                    href: "/dienstleistungen/kreditrechner/",
                }}
            />
            <ArticleHeader
                showCalc={true}
                h1Title={"Vergleich deiner Hausbank mit vielen weiteren Banken"}
                calcCTA={"Finanzierbarkeit prüfen"}
            />
            <Cooperation hasGreyBg hasMarginTop />
            <HomeInfoBoxes hasMarginTop />
            <MediaLogos hasGreyBg hasMarginTop />
            <Article hasMarginTop>
                <h2>Kreditrechner</h2>
                <p>
                    Wer gro&szlig;e Pl&auml;ne hat, braucht meist auch die entsprechenden Finanzmittel. Ein Kredit muss
                    also her. Aber mit welchen Rahmenwerten sollte man in die Verhandlung mit der Bank gehen? Welche
                    Laufzeit ist die beste? Und bei welcher Bank in &Ouml;sterreich bekommt man den besten{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    ?
                </p>
                <p>
                    Mit dem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    von miracl berechnest du einfach und schnell die wichtigsten Eckdaten deines Wunschkredits. Einfach
                    den gew&uuml;nschten Kreditbetrag festlegen, die gew&uuml;nschte Laufzeit einstellen, zwischen fixem
                    und variablem Zins w&auml;hlen und der Rechner erstellt dein erstes individuelles Angebot. Dabei
                    bekommst du auch bereits einen ersten Vorgeschmack davon, wie hoch deine monatliche Rate sein
                    k&ouml;nnte. Dabei handelt es sich um ein erstes Vorangebot, mit dem du bereits online deine
                    Wunschfinanzierung pr&uuml;fen kannst &ndash; online und kostenlos!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wir kontaktieren dich auf Wunsch und besprechen mit dir alle Details.</h2>
                <p>Mit miracl profitierst du gleich mehrfach:</p>
                <ul>
                    <li>&Ouml;sterreichweiter Kreditvergleich</li>
                    <li>Tagesaktuelle Vergleiche</li>
                    <li>Schnelle Bearbeitung &amp; garantiert g&uuml;nstig</li>
                </ul>
                <p>
                    Mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    bekommst du online nicht nur ein kostenloses Vorangebot f&uuml;r deinen Wohntraum. Unsere Experten
                    nehmen sich auch Zeit, um die richtige Finanzierung f&uuml;r dein Traumprojekt zu finden. Wir sind
                    n&auml;mlich keine Bank, sondern ein Team von unabh&auml;ngigen Experten, die t&auml;glich
                    unterschiedlichste{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    vergleichen und an Kunden vermitteln. Und wenn du es dir w&uuml;nscht, kontaktieren wir dich gerne
                    auch telefonisch, um alle Details zu deinem Kredit-Angebot mit dir zu besprechen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Warum sollte ich Kreditangebote vergleichen?</h2>
                <p>
                    Jede Bank und jedes Kreditinstitut folgt einer eigenen Gesch&auml;ftspolitik. In &Ouml;sterreich
                    agieren mehr als 100 Banken und Kreditinstitute, da ist es f&uuml;r den einzelnen nahezu
                    unm&ouml;glich, s&auml;mtliche Angebote, jeden Zins und jede Rate im Kopf zu haben &ndash; oder gar
                    objektiv vergleichen zu k&ouml;nnen. Au&szlig;erdem ist es oft notwendig, gewisse Faktoren wie
                    Verm&ouml;gen, existierende monatliche Belastungen, monatliches Einkommen und auch Art und Ort des
                    geplanten Projekts zu kennen, bevor ein Konzept f&uuml;r die Finanzierung wirklich steht.
                </p>
                <p>
                    Genau hier setzen wir mit miracl an. Mit uns kannst du nicht nur deine Tr&auml;ume eingehend
                    besprechen, mit uns profitierst du auch von einem echten, unabh&auml;ngigen Vergleich und
                    erh&auml;ltst noch in derselben Woche Angebote f&uuml;r deinen Kredit.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie viel kann ich im Monat f&uuml;r einen Kredit zahlen?</h2>
                <p>
                    Bei der Frage wie hoch die monatliche Rate maximal sein sollte, kann man sich an bew&auml;hrte
                    Regeln halten. Wer also gerade versucht, einen Kredit samt Zins zu berechnen, der sollte sich an
                    seinem monatlichen Einkommen orientieren. Im Regelfall sollten zwischen 30 % und 35 % des Einkommens
                    f&uuml;r die Tilgung eines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    zur Verf&uuml;gung stehen.
                </p>
                <p>
                    Mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    siehst du mit nur wenigen Klicks sofort, wie die monatliche Rate grob aussehen wird. Der
                    Kreditrechner nimmt daf&uuml;r deinen gew&uuml;nschten Kreditbetrag und deine gew&uuml;nschte
                    Laufzeit als Parameter, um dir schnell und direkt dar&uuml;ber Auskunft zu geben, wie hoch die
                    monatliche Rate ausfallen k&ouml;nnte.
                </p>
                <hr />

                <h2>Was kommt nach dem Kreditrechner?</h2>
                <p>
                    Am einfachsten machst du es dir, wenn du direkt online mit unserem Team Kontakt aufnimmst und einen
                    Vergleich anforderst. Wir suchen daraufhin ganz in &Ouml;sterreich nach dem passenden Angebot und
                    lassen es dir schnell zukommen. Oft passiert es, dass wir dir gleich mehrere Angebote unterbreiten
                    k&ouml;nnen, die allesamt g&uuml;nstig sind. Du suchst dir daraufhin einfach den Kredit aus, der dir
                    am meisten zusagt.
                </p>
                <hr />

                <h2>Kann ich den Kreditrechner auch f&uuml;r eine g&uuml;nstige Umschuldung benutzen?</h2>
                <p>
                    Absolut! Gerade was bestehende Hypotheken und{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    betrifft, lohnt sich ein Vergleich f&uuml;r ganz &Ouml;sterreich erst so richtig! Viel zu viele
                    Menschen zahlen n&auml;mlich noch Kredite zur&uuml;ck, die bereits ein paar Jahre alt sind und bei
                    denen die monatliche Rate oder der effektive Zins inzwischen viel zu hoch sind. Hier kann ein
                    Vergleich helfen, einen neuen Kredit zu finden und auf die restliche Laufzeit f&uuml;nfstellige
                    Betr&auml;ge zu sparen. Kurz gesagt: Ein Vergleich lohnt sich immer!
                </p>
                <p>
                    miracl arbeitet mit Spezialisten aus allen Bundesl&auml;ndern zusammen und vergleicht t&auml;glich
                    Kreditangebote aus ganz &Ouml;sterreich nach mehreren Parametern. Wenn du also eine bestehende
                    Finanzierung umschulden m&ouml;chtest, eine Rate vergleichen oder berechnen m&ouml;chtest, dann bist
                    du mit unseren Online Angeboten bestens beraten. Wir verhelfen unseren Kunden schon innerhalb von 48
                    Stunden zu ma&szlig;geschneiderten Umschuldungskrediten!{" "}
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Probier es jetzt aus!
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"kreditrechner"}></BreadcrumbList>
            <FaqStructuredData />
            <LocalBusinessStructuredData />
        </Layout>
    );
};

export default Kreditrechner;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: ["header", "footer", "mortgageCalc", "page.dienstleistungen.kreditrechner", "kreditrechnerFaq"]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
